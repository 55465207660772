/* You can add global styles to this file, and also import other style files */

div.pagecontent {
	position: absolute;
	padding: 0 15%;
	top: 28%;
	z-index: 0;
}

label.pagecontent {
	padding: 0 15%;
}

body {
	background-color: #c1c1c1;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
